<template>
  <section class="banner" :class="{ 'banner--mobile': isMobile }">
    <div class="container">
      <div class="content__row">
        <div class="content__column column--4 column-desktop--3">
          <div class="banner__content mobile_container mobile__container--1rem"
            :class="{ 'banner__content--video': iframeUrl != null }">
            <div class="banner__content__wrapper">
              <slot name="content"></slot>
              <slot v-if="!isMobile" name="button"></slot>
            </div>
          </div>
        </div>
        <div class="content__column column--4 column-desktop--9">
          <div class="banner__media">
            <iframe id="banner__media__video" v-if="iframeUrl" :src="iframeUrl" title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowfullscreen></iframe>
            <slot name="media" v-else></slot>

            <MediaShape :color="shapeColor" v-if="!iframeUrl" top="58px" :left="iframeUrl ? '70px' : '200px'" />
            <MediaShape :color="shapeColor" v-if="!iframeUrl" bottom="58px" right="70px" :upsideDown="true" />
          </div>
        </div>
      </div>
      <div v-if="isMobile" class="content__row mobile_container">
        <div class="content__column column--4 only-mobile">
          <slot name="button"></slot>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import MediaShape from "@/presentation/components/MediaShape.vue";

export default {
  name: "app-banner",

  props: {
    shapeColor: {
      type: String,
      required: true,
    },
    iframeUrl: {
      type: String,
      required: false,
      default: null,
    }
  },

  computed: {
    isMobile() {
      if (window.screen.width <= 767) {
        return true;
      } else {
        return false;
      }
    },
  },

  components: { MediaShape },
};
</script>
<style lang=""></style>
