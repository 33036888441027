<template>
  <main class="home">
    <Banner shapeColor="orange">
      <template v-slot:content
        ><h1>
          O crescimento da sua empresa passa pela
          <strong class="text-purple">Sua Sede</strong>.
        </h1>
        <p>
          Somos uma solução de <strong>escritório virtual</strong> para
          empreendedores que desejam obter uma
          <strong>presença comercial</strong> marcante e transmitir mais
          <strong>confiança</strong> e <strong>profissionalismo</strong> aos
          seus clientes e parceiros.
        </p>
      </template>
      <template v-slot:button>
        <Button type="primary" @click="openFormNav($event)">
          Fale conosco hoje mesmo
        </Button>
      </template>
      <template v-slot:media>
        <img
          v-lazy="$getWebpUrl('banner_home')"
          alt="Sua Sede melhores endereços virtual legalização contabilidade digital"
        />
      </template>
    </Banner>

    <AdvantagesSection sectionClass="section-default--no-padding-bottom" />

    <!-- <SocialProofSection sectionClass="section-default--no-padding-bottom" /> -->

    <OurSolutionsSection />

    <section
      class="content-alternate-positions section-default section-default--no-padding-top"
    >
      <div class="container"><h2>Por que escolher a Sua Sede?</h2></div>
      <div class="container">
        <div class="content__row flex-v-center">
          <div class="content__column column-4 column-desktop--6">
            <div class="content-alternate-positions__media">
              <img
                v-lazy="$getWebpUrl('why_us-professional_address')"
                alt="Endereço virtual fiscal comercial"
                class="content-alternate-positions__media__image"
              />
              <MediaShape
                color="orange"
                right="40px"
                bottom="-46px"
                :upsideDown="true"
              />
            </div>
          </div>
          <div class="content__column column-4 column-desktop--6">
            <h3>
              Trabalhe do seu jeito, sem abrir mão de um endereço corporativo
            </h3>
            <p>
              Soluções inovadoras para apoiar o crescimento do seu negócio, nos
              melhores endereços, com o melhor custo-benefício, onde e quando
              você precisar.
            </p>
            <Button :link="{ name: 'tax-address' }"> Conheça mais </Button>
          </div>
        </div>

        <!-- <div class="content__row flex-v-center">
          <div class="content__column column-4 column-desktop--6">
            <h3>Apoio na formalização e contabilidade para seu negócio</h3>
            <p>
              Ajudamos você a economizar tempo, oferecendo assessoria contábil
              por meio da nossa rede de parceiros especializados, permitindo que
              você foque realmente naquilo que importa: o seu negócio
            </p>
            <Button type="primary" @click="openFormNav($event)">
              Entre em contato
            </Button>
          </div>
          <div class="content__column column-4 column-desktop--6">
            <div class="content-alternate-positions__media">
              <img
                src="@/assets/images/webp/why_us-support.webp"
                alt="Formalização legalização contabilidade para pequenas empresas"
                class="content-alternate-positions__media__image"
              />
              <MediaShape color="orange" top="-57px" left="37px" />
            </div>
          </div>
        </div> -->
      </div>
    </section>

    <FormSection />

    <TestimonialsSection
      sectionClass="section-default--full-padding"
      @openFormNav="openFormNav($event)"
    />

    <HighlightAddressSection sectionClass="section-default--no-padding-top" />

    <!-- <BlogSection sectionClass="section-default--full-padding-bottom" /> -->
  </main>
</template>
<script>
import Banner from "@/presentation/modules/Banner.vue";
import Button from "@/presentation/components/Button.vue";
import AdvantagesSection from "@/presentation/modules/AdvantagesSection.vue";
// import SocialProofSection from "@/presentation/modules/SocialProofSection.vue";
import MediaShape from "@/presentation/components/MediaShape.vue";
import FormSection from "@/presentation/modules/FormSection.vue";
import TestimonialsSection from "@/presentation/modules/TestimonialsSection.vue";
import OurSolutionsSection from "../modules/OurSolutionsSection.vue";
import HighlightAddressSection from "../modules/HighlightAddressSection.vue";
// import BlogSection from "../modules/BlogSection.vue";

export default {
  name: "app-home",

  methods: {
    openFormNav(event) {
      this.$emit("openFormNav", event);
    },
  },

  components: {
    Banner,
    Button,
    AdvantagesSection,
    // SocialProofSection,
    MediaShape,
    FormSection,
    TestimonialsSection,
    OurSolutionsSection,
    HighlightAddressSection,
    // BlogSection,
  },
};
</script>
