import { createApp } from "vue";
import * as VueRouter from "vue-router";
import routes from "./routes";

import App from "./App.vue";
import VueLazyload from "vue-lazyload";
import { initializeApp } from "firebase/app";

const firebaseConfig = {
  apiKey: process.env.VUE_APP_FIREBASE_APIKEY,
  authDomain: process.env.VUE_APP_FIREBASE_AUTHDOMAIN,
  projectId: process.env.VUE_APP_FIREBASE_PROJECTID,
  storageBucket: process.env.VUE_APP_FIREBASE_STORAGEBUCKET,
  messagingSenderId: process.env.VUE_APP_FIREBASE_MESSAGINGSENDERID,
  appId: process.env.VUE_APP_FIREBASE_APPID,
  measurementId: process.env.VUE_APP_FIREBASE_MEASUREMENTID,
};

// Route definition
const router = VueRouter.createRouter({
  linkActiveClass: "active",
  history: VueRouter.createWebHistory(),
  routes,
  scrollBehavior() {
    // always scroll to top
    return { top: 0 };
  },
});

// App config
const app = createApp(App);

app.config.globalProperties.$firebase = initializeApp(firebaseConfig);

app.config.globalProperties.$getWebpUrl = (img) => {
  var images = require.context("@/assets/images/webp/", false, /\.webp$/);
  return images("./" + img + ".webp");
};

app.config.globalProperties.$getSvgIconUrl = (img) => {
  var images = require.context("@/assets/images/svg/icons/", false, /\.svg$/);
  return images("./" + img + ".svg");
};

app.config.globalProperties.$getPngUrl = (img) => {
  var images = require.context("@/assets/images/png/", false, /\.png$/);
  return images("./" + img + ".png");
};

app.config.globalProperties.$getJpgUrl = (img) => {
  var images = require.context("@/assets/images/jpg/", false, /\.jpeg$/);
  return images("./" + img + ".jpeg");
};

app.use(router);
app.use(VueLazyload);

// App mount
app.mount("#app");

// CSS Libs
import "materialize-css/dist/css/materialize.min.css";
import "./assets/styles/index.scss";
