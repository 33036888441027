<template lang="">
  <section :class="`section-default testimonials ${sectionClass}`">
    <div class="container">
      <div class="testimonials__content-row content__row">
        <div
          class="testimonials__content-column content__column column-4 column-desktop--4"
        >
          <h2 class="heading-1 font-regular">
            Veja a opinião de quem já usa nossas soluções.
          </h2>

          <Button type="tertiary" @click="openFormNav($event)"
            >Quero conhecer mais</Button
          >
        </div>
        <div class="content__column column-4 column-desktop--8">
          <swiper
            class="swiper testimonials-carousel"
            :modules="modules"
            :space-between="25"
            :width="isMobile ? 180 : null"
            :slides-per-view="isMobile ? 1 : 2"
            :pagination="{ clickable: true, dynamicBullets: true }"
          >
            <!-- <swiper-slide class="slide">
              <div class="testimonials-carousel__item">
                <div class="testimonials-carousel__item__media">
                  <img
                    src="@/assets/images/svg/icons/icon_quotation_marks.svg"
                    alt="icon_quotation_marks"
                  />
                </div>

                <p class="testimonials-carousel__item__content">
                  "Foi uma experiência muito boa, espaço grande e com vários
                  lugares diferentes para escolher."
                </p>
                <h4 class="testimonials-carousel__item__title">Ana Simões</h4>
                <h5 class="testimonials-carousel__item__subtitle">WOBA</h5>
              </div>
            </swiper-slide>
            <swiper-slide class="slide">
              <div class="testimonials-carousel__item">
                <div class="testimonials-carousel__item__media">
                  <img
                    src="@/assets/images/svg/icons/icon_quotation_marks.svg"
                    alt="icon quotation marks"
                  />
                </div>
                <p class="testimonials-carousel__item__content">
                  "Como sempre muito bom!!! Silencioso, confortável, internet de
                  qualidade e bem localizado."
                </p>
                <h4 class="testimonials-carousel__item__title">Cintia Taiar</h4>
                <h5 class="testimonials-carousel__item__subtitle">WOBA</h5>
              </div>
            </swiper-slide>
            <swiper-slide class="slide">
              <div class="testimonials-carousel__item">
                <div class="testimonials-carousel__item__media">
                  <img
                    src="@/assets/images/svg/icons/icon_quotation_marks.svg"
                    alt="icon quotation marks"
                  />
                </div>

                <p class="testimonials-carousel__item__content">
                  "Ao lado do metrô, colaboradores prestativos e atenciosos.
                  Ambiente confortável, limpo e organizado."
                </p>
                <h4 class="testimonials-carousel__item__title">
                  Darlam Santos
                </h4>
                <h5 class="testimonials-carousel__item__subtitle">WOBA</h5>
              </div>
            </swiper-slide>
            <swiper-slide class="slide">
              <div class="testimonials-carousel__item" href="#one!">
                <div class="testimonials-carousel__item__media">
                  <img
                    src="@/assets/images/svg/icons/icon_quotation_marks.svg"
                    alt="icon quotation marks"
                  />
                </div>

                <p class="testimonials-carousel__item__content">
                  "O espaço é maravilhoso, nos atenderam super bem e a sala
                  estava limpa, organizadinha e tinha até café pra gente! AMEI e
                  voltaremos sempre!"
                </p>
                <h4 class="testimonials-carousel__item__title">
                  Gustavo Avelino
                </h4>
                <h5 class="testimonials-carousel__item__subtitle">WOBA</h5>
              </div>
            </swiper-slide>
            <swiper-slide class="slide">
              <div class="testimonials-carousel__item" href="#one!">
                <div class="testimonials-carousel__item__media">
                  <img
                    src="@/assets/images/svg/icons/icon_quotation_marks.svg"
                    alt="icon quotation marks"
                  />
                </div>

                <p class="testimonials-carousel__item__content">
                  "Lugar bem tranquilo e silencioso para trabalhar. A equipe que
                  trabalha lá é muito prestativa e atenciosa."
                </p>
                <h4 class="testimonials-carousel__item__title">
                  Alex Serikaku
                </h4>
                <h5 class="testimonials-carousel__item__subtitle">WOBA</h5>
              </div>
            </swiper-slide> -->
            <swiper-slide class="slide">
              <div class="testimonials-carousel__item" href="#one!">
                <div class="testimonials-carousel__item__media">
                  <img
                    src="@/assets/images/svg/icons/icon_quotation_marks.svg"
                    alt="icon quotation marks"
                  />
                </div>

                <p class="testimonials-carousel__item__content">
                  "Fiquei e estou muito satisfeito em fechar essa parceria com a
                  Sua Sede, tem um atendimento ágil e fácil de operar, fui muito
                  bem atendido pela consultora Nayris, tive todas as dúvidas
                  iniciais esclarecidas e entre muitas propostas decidi optar
                  pela Sua Sede pelo atendimento recebido onde foi demostrado
                  todas as vantagens e hoje sou seu cliente. Muito obrigado!!"
                </p>
                <h4 class="testimonials-carousel__item__title">
                  Adônis Gonçalves Junior
                </h4>
                <!-- <h5 class="testimonials-carousel__item__subtitle">WOBA</h5> -->
              </div>
            </swiper-slide>
            <swiper-slide class="slide">
              <div class="testimonials-carousel__item" href="#one!">
                <div class="testimonials-carousel__item__media">
                  <img
                    src="@/assets/images/svg/icons/icon_quotation_marks.svg"
                    alt="icon quotation marks"
                  />
                </div>

                <p class="testimonials-carousel__item__content">
                  "Estou muito satisfeito com o atendimento e serviços prestados
                  pela Sua Sede. Somos clientes desde 2023 e gostamos muito das
                  instalações e toda infraestrutura do espaço, que atende
                  perfeitamente nossas necessidades e interesses. Nós da One
                  Design Agência de Marketing 360º recomendamos fortemente os
                  serviços da Sua Sede para qualquer empresário que desejar
                  aliar beneficio, custo, sofisticação e excelência no
                  atendimento a seu negócio!"
                </p>
                <h4 class="testimonials-carousel__item__title">
                  Ricardo Pereira da Silva
                </h4>
                <h5 class="testimonials-carousel__item__subtitle">
                  CEO - One Design
                </h5>
              </div>
            </swiper-slide>
            <swiper-slide class="slide">
              <div class="testimonials-carousel__item" href="#one!">
                <div class="testimonials-carousel__item__media">
                  <img
                    src="@/assets/images/svg/icons/icon_quotation_marks.svg"
                    alt="icon quotation marks"
                  />
                </div>

                <p class="testimonials-carousel__item__content">
                  "Fui super bem atendido, ainda não tive a oportunidade de
                  utilizar o espaço, porém, estou super satisfeito com o
                  atendimento recebido."
                </p>
                <h4 class="testimonials-carousel__item__title">
                  Tadeu Velez da Silva
                </h4>
                <h5 class="testimonials-carousel__item__subtitle">
                  CEO - DNA Pesquisa
                </h5>
              </div>
            </swiper-slide>
            <swiper-slide class="slide">
              <div class="testimonials-carousel__item" href="#one!">
                <div class="testimonials-carousel__item__media">
                  <img
                    src="@/assets/images/svg/icons/icon_quotation_marks.svg"
                    alt="icon quotation marks"
                  />
                </div>

                <p class="testimonials-carousel__item__content">
                  "Bom atendimento e serviços prestados dentro do contratado."
                </p>
                <h4 class="testimonials-carousel__item__title">
                  José Ruben Marone
                </h4>
                <h5 class="testimonials-carousel__item__subtitle">
                  CEO - Campedelli Advogados Associados
                </h5>
              </div>
            </swiper-slide>
          </swiper>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import Button from "@/presentation/components/Button.vue";
import { Pagination, FreeMode } from "swiper";
import { Swiper, SwiperSlide } from "vue-awesome-swiper";

import "swiper/css";
import "swiper/css/pagination";

export default {
  name: "app-testimonials-section",

  components: { Button, Swiper, SwiperSlide },

  props: {
    sectionClass: {
      type: String,
      required: false,
    },
  },

  data() {
    return {
      itemWidth: null,
      itemsLength: null,
    };
  },

  computed: {
    isMobile() {
      if (window.screen.width <= 767) {
        return true;
      } else {
        return false;
      }
    },
  },

  mounted() {
    const items = document.getElementsByClassName(
      "testimonials-carousel__item"
    );
    this.itemWidth = items[0].offsetWidth;
    this.itemLength = items.length;
  },

  methods: {
    openFormNav(event) {
      this.$emit("openFormNav", event);
    },
  },

  setup() {
    return {
      modules: [Pagination, FreeMode],
    };
  },
};
</script>
<style lang=""></style>
