<template>
  <main class="legalization">
    <Banner shapeColor="orange">
      <template v-slot:content
        ><h1>Espaços Sob Demanda</h1>
        <p>
          Oferecemos espaços de acordo com a sua necessidade, no coração de São
          Paulo. Ajudamos sua empresa a encontrar a melhor solução para que você
          foque no seu próprio negócio
        </p>
      </template>
      <template v-slot:button>
        <Button @click="openFormNav($event)" type="primary">
          Entre em contato e saiba mais
        </Button>
      </template>
      <template v-slot:media>
        <img
          v-lazy="$getWebpUrl('banner-legalization')"
          alt="Legalização de empresas"
        />
      </template>
    </Banner>

    <section
      class="section-default content-alternate-positions content-alternate-positions--one-item"
    >
      <div class="container">
        <div class="content__row flex-v-center">
          <div class="content__column column-4 column-desktop--6">
            <div class="content-alternate-positions__media">
              <img
                v-lazy="$getWebpUrl('on-demand--1')"
                alt="Espaço Compartilhado"
                class="content-alternate-positions__media__image"
              />
              <MediaShape
                color="orange"
                right="40px"
                bottom="-46px"
                :upsideDown="true"
              />
            </div>
          </div>
          <div class="content__column column-4 column-desktop--6">
            <h3>Espaço Compartilhado</h3>
            <p>
              Para você que quer trabalhar em um ambiente propício para
              networking e geração de negócios.
            </p>
            <Button
              @click="openFormNav({ interestPlace: 'Espaço Compartilhado' })"
            >
              Fale conosco e saiba mais
            </Button>
          </div>
        </div>

        <div class="content__row flex-v-center text-right">
          <div class="content__column column-4 column-desktop--6">
            <h3>Salas de Reunião</h3>
            <p>
              Precisa de um espaço exclusivo para realizar suas reuniões? Temos
              a solução perfeita para você!
            </p>
            <Button @click="openFormNav({ interestPlace: 'Salas de Reunião' })">
              Fale conosco e saiba mais
            </Button>
          </div>
          <div class="content__column column-4 column-desktop--6">
            <div class="content-alternate-positions__media">
              <img
                v-lazy="$getWebpUrl('on-demand--2')"
                alt="Salas de Reunião"
                class="content-alternate-positions__media__image"
              />
              <MediaShape color="orange" top="-57px" left="37px" />
            </div>
          </div>
        </div>

        <div class="content__row flex-v-center no-pad-t">
          <div class="content__column column-4 column-desktop--6">
            <div class="content-alternate-positions__media">
              <img
                v-lazy="$getWebpUrl('on-demand--3')"
                alt="Salas Privativas"
                class="content-alternate-positions__media__image"
              />
              <MediaShape
                color="orange"
                right="40px"
                bottom="-46px"
                :upsideDown="true"
              />
            </div>
          </div>
          <div class="content__column column-4 column-desktop--6">
            <h3>Salas Privativas</h3>
            <p>
              Precisa de foco para atingir suas metas? Conheça nossas salas
              privativas que oferecem conforto e praticidade.
            </p>
            <Button @click="openFormNav({ interestPlace: 'Salas Privativas' })">
              Fale conosco e saiba mais
            </Button>
          </div>
        </div>

        <div class="content__row flex-v-center text-right">
          <div class="content__column column-4 column-desktop--6">
            <h3>Auditório de Evento</h3>
            <p>
              Quer realizar seus eventos em um lugar diferenciado, com prestígio
              e excelente localização? Conheça nossos auditórios.
            </p>
            <Button
              @click="openFormNav({ interestPlace: 'Auditório de Evento' })"
            >
              Fale conosco e saiba mais
            </Button>
          </div>
          <div class="content__column column-4 column-desktop--6">
            <div class="content-alternate-positions__media">
              <img
                v-lazy="$getWebpUrl('on-demand--4')"
                alt="Auditório de Evento"
                class="content-alternate-positions__media__image"
              />
              <MediaShape color="orange" top="-57px" left="37px" />
            </div>
          </div>
        </div>
      </div>
    </section>
  </main>
</template>
<script>
import Banner from "@/presentation/modules/Banner.vue";
import Button from "@/presentation/components/Button.vue";
import MediaShape from "@/presentation/components/MediaShape.vue";

export default {
  name: "app-place-on-demand",

  data() {
    return {};
  },

  methods: {
    openFormNav(event) {
      this.$emit("openFormNav", event);
    },
  },

  components: {
    Banner,
    Button,
    MediaShape,
  },
};
</script>
<style lang=""></style>
