<template>
  <section :class="`section-default ${sectionClass}`">
    <div class="container">
      <h2>Vantagens do Escritório Virtual</h2>

      <section
        class="section-default content-alternate-positions content-alternate-positions--one-item"
      >
        <div class="container">
          <div class="content__row flex-v-center">
            <div class="content__column column-4 column-desktop--6">
              <div class="content-alternate-positions__media">
                <img
                  v-lazy="$getWebpUrl('advantages--1')"
                  alt="Credibilidade"
                  class="content-alternate-positions__media__image"
                />
                <MediaShape
                  color="orange"
                  right="40px"
                  bottom="-46px"
                  :upsideDown="true"
                />
              </div>
            </div>
            <div class="content__column column-4 column-desktop--6">
              <h3>Credibilidade</h3>
              <p>Sua empresa em um endereço de prestígio em São Paulo.</p>
              <Button @click="openFormNav($event)">
                Fale conosco e saiba mais
              </Button>
            </div>
          </div>

          <div class="content__row flex-v-center text-right">
            <div class="content__column column-4 column-desktop--6">
              <h3>Infraestrutura</h3>
              <p>
                Salas de reunião, espaços compartilhados e salas privativas
                disponíveis para você reservar quando precisar.
              </p>
              <Button @click="openFormNav($event)">
                Fale conosco e saiba mais
              </Button>
            </div>
            <div class="content__column column-4 column-desktop--6">
              <div class="content-alternate-positions__media">
                <img
                  v-lazy="$getWebpUrl('advantages--2')"
                  alt="Infraestrutura"
                  class="content-alternate-positions__media__image"
                />
                <MediaShape color="orange" top="-57px" left="37px" />
              </div>
            </div>
          </div>

          <div class="content__row flex-v-center no-pad-t">
            <div class="content__column column-4 column-desktop--6">
              <div class="content-alternate-positions__media">
                <img
                  v-lazy="$getWebpUrl('advantages--3')"
                  alt="Economia"
                  class="content-alternate-positions__media__image"
                />
                <MediaShape
                  color="orange"
                  right="40px"
                  bottom="-46px"
                  :upsideDown="true"
                />
              </div>
            </div>
            <div class="content__column column-4 column-desktop--6">
              <h3>Economia</h3>
              <p>
                Você não precisa pagar valores altos de aluguel, condomínio e
                IPTU para estar em uma região de destaque.
              </p>
              <Button @click="openFormNav($event)">
                Fale conosco e saiba mais
              </Button>
            </div>
          </div>

          <div class="content__row flex-v-center text-right">
            <div class="content__column column-4 column-desktop--6">
              <h3>Serviços profissionais</h3>
              <p>
                Gestão de correspondência e atendimento telefônico personalizado
                para você focar no seu negócio.
              </p>
              <Button @click="openFormNav($event)">
                Fale conosco e saiba mais
              </Button>
            </div>
            <div class="content__column column-4 column-desktop--6">
              <div class="content-alternate-positions__media">
                <img
                  v-lazy="$getWebpUrl('advantages--4')"
                  alt="Serviços profissionais"
                  class="content-alternate-positions__media__image"
                />
                <MediaShape color="orange" top="-57px" left="37px" />
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </section>
</template>
<script>
import Button from "@/presentation/components/Button.vue";
import MediaShape from "@/presentation/components/MediaShape.vue";

export default {
  name: "app-advantages",

  components: {
    Button,
    MediaShape,
  },

  props: {
    sectionClass: {
      type: String,
      required: false,
    },
  },

  data() {
    return {};
  },

  computed: {
    isMobile() {
      if (window.screen.width <= 767) {
        return true;
      } else {
        return false;
      }
    },
  },

  setup() {
    return {};
  },
};
</script>
